<template>
  <div class="mprs-header-div">
    <div v-if="SuccessBannerData">
      <BannerMessage
        v-if="
          SuccessBannerData.bannerType === 'success' &&
          SuccessBannerData.showBanner
        "
        :successBanner="true"
        :message="SuccessBannerData.bannerMessage"
        @hideBanner="hideSuccessBanner"
      ></BannerMessage>
    </div>
    <div v-if="ErrorBannerData">
      <BannerMessage
        v-if="
          ErrorBannerData.bannerType === 'error' && ErrorBannerData.showBanner
        "
        :errorBanner="true"
        :message="ErrorBannerData.bannerMessage"
        :hasList="ErrorBannerData.hasList"
        :errorList="ErrorBannerData.errorList"
        @hideBanner="hideErrorBannerMessage"
      ></BannerMessage>
    </div>

    <div class="header-details-info allPagecolor">
      <v-row cols="12" v-if="projectDetails" class="projectRow">
        <div
          v-if="projectDetails?.keyMPMSfieldsMessage"
          class="railroadError"
          style="width: 100%"
        >
          <v-icon icon="mdi-alert"></v-icon>
          {{ projectDetails?.keyMPMSfieldsMessage }}
        </div>
        <div
          v-if="!projectDetails?.federalProjectStatus"
          class="railroadError"
          style="width: 100%"
          :class="{ 'mt-2': projectDetails?.fedStatusMessage }"
        >
          <v-icon icon="mdi-alert"></v-icon>
          {{ projectDetails?.fedStatusMessage }}
        </div>
        <div class="w-full d-flex">
          <OnlineHelpPOC :helpKey="'PRJINFHELP'"></OnlineHelpPOC>
        </div>
        <v-row
          cols="12"
          v-if="projectDetails"
          class="project-details-info allPagecolor"
        >
          <v-col cols="3" class="projectStatusRow">
            <div class="projectStatusBubble">
              <div class="projectStatus color-blue">
                <v-row cols="12">
                  <v-col cols="6">
                    <v-label class="statusText1">PROJECT STATUS</v-label>
                  </v-col>
                </v-row>
                <v-row cols="12">
                  <v-col cols="12">
                    <v-label class="statusText2">{{
                      projectDetails?.projectStatusWFDTO?.projectStatusName
                    }}</v-label>
                  </v-col>
                </v-row>
              </div>
              <div
                class="mb-10"
                v-if="
                  projectDetails?.projectStatusWFDTO?.projectStatusCode !==
                    'ST_ON_HOLD' && isChangeStatusAccess
                "
              >
                <SecondaryButton
                  :buttonText="'CHANGE STATUS'"
                  class="changeStatusButton"
                  @buttonClicked="changeStatusButtonClicked()"
                ></SecondaryButton>
              </div>
            </div>
          </v-col>
          <v-col>
            <div class="w-100 mb-20">
              <h3 class="mpmsHeader w-90 text-left justify-content-start">
                <b
                  >MPMS {{ projectDetails.mpmsNumber }} –
                  {{ projectDetails.projectTitle }}</b
                >
              </h3>
            </div>
            <div class="w-100 d-flex justify-content-between">
              <div>
                <h4 class="mpmsSubHeader">
                  <b
                    >SR-SEC:
                    <span class="subHeaderSpan" v-if="projectDetails.stateRoute"
                      >{{ projectDetails.stateRoute }}-{{
                        projectDetails.srSection
                      }}</span
                    >
                    <span class="subHeaderSpan" v-else>{{
                      projectDetails.srSection
                    }}</span></b
                  >
                </h4>
              </div>
              <div>
                <h4 class="mpmsSubHeader">
                  <b
                    >County:
                    <span class="subHeaderSpan">{{
                      projectDetails.county
                    }}</span>
                  </b>
                </h4>
              </div>
              <div>
                <h4 class="mpmsSubHeader">
                  <b
                    >Municipality:
                    <span class="subHeaderSpan"
                      >{{ projectDetails.municipality }}
                      {{ projectDetails.city }}</span
                    >
                  </b>
                </h4>
              </div>
              <div class="manage-projects-list">
                <v-menu
                  v-model="menuOpen"
                  open-on-click
                  :close-on-content-click="false"
                >
                  <template v-slot:activator="{ props }">
                    <v-btn v-bind="props" class="mapButton">
                      <span class="mapButtonText">{{ menuTitle }}</span>
                      <i class="fa fa-caret-down" style="color: white"></i>
                    </v-btn>
                  </template>
                  <v-list :items="getMenuItems">
                    <v-list-item
                      class="subheader subHeader-min-height"
                      v-for="(menu, index) in getMenuItems"
                      :key="index"
                      :disabled="
                        menu.subtitle == 'PUC Communications' &&
                        isPucApprovalRequired
                      "
                      @click="handleMenuSelection(menu.value)"
                    >
                      <v-list-group
                        :value="menu.title"
                        v-if="menu?.items"
                        v-model="menu.active"
                        style="padding-left: 20px; padding-right: 20px"
                      >
                        <template v-slot:activator="{ props }">
                          <v-list-item
                            v-bind="props"
                            class="subHeader-min-height"
                          >
                            <v-list-item-content>
                              <v-list-item-title
                                class="submenu highlight-on-hover subHeader-min-height p-0"
                              >
                                {{ menu.title }}
                              </v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                        </template>
                        <v-list-item
                          v-for="child in menu?.items"
                          :key="child"
                          :disabled="!child.value || !child?.showItem"
                          @click="handleMenuSelection(child.value)"
                          class="subHeader-min-height"
                        >
                          <v-list-item-content>
                            <v-list-item-title
                              class="submenu highlight-on-hover subHeader-min-height"
                              :active-class="hoverClass"
                            >
                              {{ child.subtitle }}
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list-group>
                      <v-list-item-content v-if="!menu?.items">
                        <v-list-item-title
                          class="submenu highlight-on-hover"
                          :active-class="hoverClass"
                        >
                          {{ menu.subtitle }}
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-menu>
                <SecondaryButton
                  v-if="
                    projectDetails?.followProject ||
                    projectDetails?.unFollowProject
                  "
                  :buttonText="
                    (projectDetails?.followProject ? 'FOLLOW' : 'UNFOLLOW') +
                    ' Project'
                  "
                  class="mt-5"
                  :disabled="!isFollowUnfollowButtonAccess?.update"
                  @buttonClicked="followUnfollowButtonClicked()"
                ></SecondaryButton>
                <p v-if="projectDetails?.unFollowProject" class="follow-text">
                  &nbsp;You are following this project
                </p>
              </div>
              <div></div>
            </div>
          </v-col>
        </v-row>

        <v-row class="projectIdentifierRow">
          <div
            v-for="(index, value) in projectIdentifierIcons"
            class="projectIdentifierIcons"
            :key="index"
          >
            <v-label class="iconText">
              <span v-html="index"></span>
              {{ toTitleCase(projectDetails?.projectIdentifier?.[value]) }}
            </v-label>
          </div>
          <v-label
            v-if="
              projectDetails &&
              projectDetails.gcmsProject.projectPriority != 'Normal'
            "
            class="iconText"
            ><span
              v-html="
                getIdentifierIcon(projectDetails.gcmsProject.projectPriority)
              "
            ></span>
            {{ toTitleCase(projectDetails?.gcmsProject.projectPriority) }}
          </v-label>
        </v-row>
        <div class="d-flex align-items-center">
          <div class="w-50">
            <SecondaryButton
              style="padding-bottom: 20px"
              :buttonText="'Reopen Project'"
              class="ml-20"
              :disabled="!isReopenButtonAccess?.update"
              @buttonClicked="reOpenProjectButtonClicked()"
            ></SecondaryButton>
          </div>
        </div>
      </v-row>
    </div>
    <router-view @refreshMpmsData="updateData" @BannerUpdate="BannerUpdate" />
  </div>
  <DialogComponent ref="ChangeProjectStatusDialog"></DialogComponent>
  <SharedConfirmationDialog
    ref="holdUnholdProjectDialog"
  ></SharedConfirmationDialog>
</template>
<script>
import MpmsProjectDetails from "@/modules/project/scripts/MpmsProjectDetails.js";
export default {
  ...MpmsProjectDetails,

  data() {
    return {
      menuOpen: false,
    };
  },

  methods: {
    handleMenuSelection(value) {
      this.$router.push(value);

      this.menuOpen = false;
    },
  },
};
</script>
<style>
@import "@/assets/css/style.scss";
@import "@/assets/css/main.scss";
</style>
