import useFieldEntry from "@/composables/fieldEntry";import {
  mpmsLengthRule,
  mpmsRequiredRule,
  numericRule,
} from "@/composables/validationRules";
import { CONFIG_ENDPOINT } from "@/constants/Endpoints";
import { RBAC_CONTROLLER } from "@/constants/rbacconstants";
import AdminMenuPage from "@/modules/gcmsmain/admin/AdminMenu.vue";
import ProjectCreateMenu from "@/modules/gcmsmain/createproject/submenu/ProjectCreateMenu.vue";
import DistrictMenuPage from "@/modules/gcmsmain/districtfunctions/DistrictMenu.vue";
import { userRoleBasedAccessStore } from "@/stores/userRoleBasedAccess";
import { useUserStore } from "@/stores/userStore";
import axios from "axios";
import { computed, onMounted, ref, watch } from "vue";
import { onBeforeRouteLeave, useRouter } from "vue-router";
import headerPage from "../components/headerPage.vue";
export default {
  components: {
    headerPage,
    AdminMenuPage,
    DistrictMenuPage,
    ProjectCreateMenu,
  },
  props: {
    external: { type: Boolean, default: false },
  },
  emits: ["resetError"],
  setup() {
    const isCreateAccess = ref(false);
    const isGCInventory = ref(false);
    const roleBasedAccessController = ref("");
    const userRoleBasedAccessService = userRoleBasedAccessStore();
    const route = useRouter();
    const userStore = useUserStore();
    const user = computed(() => userStore.loggedInUser);
    const isAuthenticated = computed(() => userStore.isAuthenticated);
    const { keydownAllowNumberOnlyWithEnter } = useFieldEntry();
    let gradeinventoryRoute = ref(CONFIG_ENDPOINT.GCMS_MAIN_GRADE_INVENTORY);
    let advancedRoute = ref(CONFIG_ENDPOINT.ADVANCED_SEARCH);
    let mpmsIdFormValid = ref(false);
    let lastEnteredMpmsValue = ref("");
    let mpmsValue = ref("");
    let showInvalidMPMS = ref(false);
    let AdminItems = ref({
      title: "Flagger Activity Admin Table",
    });
    let errorMessage = ref("");
    watch(
      route,
      (to) => {
        mpmsValue.value = to?.params?.id;
      },
      { flush: "pre", immediate: true, deep: true }
    );
    onBeforeRouteLeave((to, from, next) => {
      showInvalidMPMS.value = false;
      next();
    });
    onMounted(async () => {
      roleBasedAccessController.value = RBAC_CONTROLLER.CREATE_PROJECT_ACCESS;
      let response = await userRoleBasedAccessService.checkUserAccess(
        roleBasedAccessController.value
      );
      isCreateAccess.value = response;
      mpmsValue.value = "";
      let gcResponse = await userRoleBasedAccessService.checkUserAccess(
        RBAC_CONTROLLER.GRADECROSSINGINVENTORYLIST_READ_ACCESS
      );
      isGCInventory.value = gcResponse;
    });
    function resetSearch() {
      showInvalidMPMS.value = false;
      lastEnteredMpmsValue.value = "";
    }
    function checkIfMpmsIsValid(enteredValue) {
      axios
        .get(
          CONFIG_ENDPOINT.GCMS_MAIN_PROJECT +
            enteredValue +
            "?userID=" +
            user.value.sub
        )
        .then((response) => {
          let projectDetails = response.data;
          if (projectDetails.mpmsNumber) {
            showInvalidMPMS.value = false;
            route.replace({ path: `/GCMS/${enteredValue}` });
          } else {
            showInvalidMPMS.value = true;
            errorMessage.value =
              "MPMS# " + lastEnteredMpmsValue.value + " does not exist";
          }
        })
        .catch((err) => {
          if (
            err.response &&
            (err.response.status == 500 || err.response.status === 404)
          ) {
            showInvalidMPMS.value = true;
            errorMessage.value = err.response.data.businessMessage;
          }
        });
    }
    function submitForm() {
      lastEnteredMpmsValue.value = "";
      showInvalidMPMS.value = false;
      if (mpmsIdFormValid.value) {
        lastEnteredMpmsValue.value = mpmsValue.value;
        let enteredValue = mpmsValue.value;
        mpmsValue.value = "";
        checkIfMpmsIsValid(enteredValue);
      } else {
        console.log("Form validation failed");
      }
    }
    function keydownAllowNumberOnly(event) {
      if (!event.keyCode != 86 && !event.ctrlKey) {
        if (isLeftUpRightDownBackspaceDeleteTab(event)) {
          return;
        }

        let regex = /\d/;

        if (!regex.test(event.key)) {
          event.preventDefault();
          return false;
        }
      }
    }

    watch(
      () => [mpmsValue.value],
      () => {
        if (mpmsValue.value) {
          mpmsValue.value = mpmsValue.value.trim();
          let regex = /^\d\d*$/;
          lastEnteredMpmsValue.value = mpmsValue.value.trim();
          if (!regex.test(lastEnteredMpmsValue.value)) {
            mpmsValue.value = "";
            lastEnteredMpmsValue.value = "";
          }
        }
      },
      { deep: true }
    );

    const goDashboard = () => {
      resetSearch();
      route.push("/GCMS");
    };

    const goCreateProject = () => {
      resetSearch();
      route.push(CONFIG_ENDPOINT.GCMS_MAIN_MPMS_PROJECT);
    };
    function isLeftUpRightDownBackspaceDeleteTab(event) {
      let key = event.key || event.code;
      return (
        key === "ArrowLeft" ||
        key === 37 ||
        key === "ArrowUp" ||
        key === 38 ||
        key === "ArrowRight" ||
        key === 39 ||
        key === "ArrowDown" ||
        key === 40 ||
        key === "Backspace" ||
        key === 8 ||
        key === "Delete" ||
        key === 46 ||
        key === "Tab" ||
        key === 9
      );
    }
    return {
      keydownAllowNumberOnlyWithEnter,
      gradeinventoryRoute,
      advancedRoute,
      mpmsIdFormValid,
      lastEnteredMpmsValue,
      mpmsValue,
      showInvalidMPMS,
      AdminItems,
      checkIfMpmsIsValid,
      submitForm,
      resetSearch,
      mpmsLengthRule,
      numericRule,
      mpmsRequiredRule,
      keydownAllowNumberOnly,
      goDashboard,
      user,
      isAuthenticated,
      goCreateProject,
      errorMessage,
      isCreateAccess,
      isGCInventory,
    };
  },
};
