<template>
  <v-app-bar class="appHeaderBar">
    <v-row>
      <v-col class="headerCol1">
        <v-row class="rowTitle">
          <div class="headerTitle1">GCMS</div>
          <div class="headerLine">|</div>
          <div class="headerTitle2">
            {{
              !external ? "GRADE CROSSINGS MANAGEMENT SYSTEM" : headerMessage
            }}
          </div>
        </v-row>
        <v-row class="rowId" v-if="!external">
          <div class="headerId">
            <v-menu open-on-hover>
              <template v-slot:activator="{ props }">
                <v-btn v-if="isAuthenticated && user" v-bind="props">
                  {{ userName }}
                </v-btn>
              </template>
              <v-card>
                <v-card-text>
                  <div class="mb-20">
                    Login Id : <b>{{ user?.sub }}</b>
                  </div>
                  <div class="mb-20">
                    Role : <b>{{ commaSeparatedCNs }}</b>
                  </div>
                </v-card-text>
              </v-card>
            </v-menu>
          </div>
        </v-row>
      </v-col>
      <v-col class="headerCol2" v-if="!external">
        <v-row class="rowItems">
          <div>
            <i class="headerFontIcons fa fa-arrow-circle-right"></i>
            <a
              class="headeritem"
              :href="constants?.ecsPortalUrl"
              target="_blank"
              >ECS Portal</a
            >
          </div>
          <div>
            <i class="headerFontIcons fa fa-paper-plane"></i>
            <a
              class="headeritem"
              :href="'https://www.penndot.pa.gov/ProjectAndPrograms/RoadDesignEnvironment/RoadDesign/Right-ofWayandUtilities/Pages/Grade-Crossing.aspx'"
              target="_blank"
              >WHAT'S NEW</a
            >
          </div>
          <div>
            <i class="headerFontIcons far fa-star"></i>
            <a
              class="headeritem"
              :href="'https://www.penndot.pa.gov/ProjectAndPrograms/RoadDesignEnvironment/RoadDesign/Right-ofWayandUtilities/Pages/Grade-Crossing.aspx'"
              target="_blank"
              >USEFUL LINKS</a
            >
          </div>
          <div>
            <i class="headerFontIcons fa fa-envelope"></i>
            <router-link class="headeritem" :to="otherRoute"
              >CONTACT US</router-link
            >
          </div>
          <div class="headeritem">
            <v-menu open-on-hover>
              <template v-slot:activator="{ props }">
                <v-btn class="headeritem" v-bind="props">
                  {{ userName }}
                  <i class="headerFontIcons fa fa-caret-down iconcolor"></i>
                </v-btn>
              </template>
              <v-list :items="menuItems">
                <v-list-item
                  class="subheader"
                  v-for="(menu, index) in menuItems"
                  :key="index"
                  >{{ menu.title }}
                  <v-list-item-content>
                    <v-list-item-title
                      class="submenu highlight-on-hover"
                      :active-class="hoverClass"
                      @click="showConfirmationDialog"
                    >
                      {{ menu.subtitle }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-menu>

            <v-dialog
              v-model="dialogVisible"
              max-width="600px"
              max-height="3000px"
            >
              <div class="dialogheading">
                <div class="w-100 d-flex justify-content-between">
                  Logout Confirmation
                  <div>
                    <v-icon @click="closeDialog" class="cursor-pointer" small
                      >mdi-close
                    </v-icon>
                  </div>
                </div>
                <v-card>
                  <v-card-text>
                    <div class="w-100 d-flex justify-content-between">
                      <div class="mpms-env-label d-flex justify-center m-20">
                        Are you sure you want to logout?
                      </div>
                    </div>
                  </v-card-text>
                  <v-card-actions class="confirmaction">
                    <div class="d-flex justify-center">
                      <PrimaryButton
                        @click="showlogoutPage"
                        :buttonText="'Yes'"
                      ></PrimaryButton>
                      <PrimaryButton
                        @click="closeDialog"
                        :buttonText="'No'"
                        class="ml-10"
                      ></PrimaryButton>
                    </div>
                  </v-card-actions>
                </v-card>
              </div>
            </v-dialog>
          </div>
        </v-row>
      </v-col>
    </v-row>
  </v-app-bar>
  <DialogComponent
    ref="logoutConfirmDialogComponent"
    :dialogVisible="dialogVisible"
  ></DialogComponent>

  <link
    rel="stylesheet"
    href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"
  />
</template>
<script>
import { CONFIG_ENDPOINT } from "@/constants/Endpoints";
import DialogComponent from "@/modules/shared/components/ConfirmationPage.vue";
import DynamicMenu from "@/modules/shared/components/DynamicMenu.vue";
import PrimaryButton from "@/modules/shared/components/PrimaryButton.vue";
import SecondaryButton from "@/modules/shared/components/SecondaryButton.vue";
import router from "@/router";
import { useUserStore } from "@/stores/userStore";
import axios from "axios";
import { computed, onMounted, ref } from "vue";
import { useDocumentStore } from "../../../../stores/document";
export default {
  components: {
    DynamicMenu,
    DialogComponent,
    PrimaryButton,
    SecondaryButton,
  },
  props: {
    external: { type: Boolean, default: false },
  },
  setup() {
    const documentStore = useDocumentStore();
    // Use ref for reactive variables
    const otherRoute = ref(CONFIG_ENDPOINT.Dashboard);
    const constants = ref(null);
    const userStore = useUserStore();
    const user = computed(() => userStore.loggedInUser);
    const isAuthenticated = computed(() => userStore.isAuthenticated);
    const menuOpen = ref(false);
    const menuName = ref(user?.value?.sub);
    const headerMessage = computed(() => documentStore.headerName);

    const userName = user?.value?.name + " " + user?.value?.family_name;
    const menuItems = ref([
      {
        subtitle: "Logout",
        value: "",
      },
    ]);
    const dialogVisible = ref(false);

    const showConfirmationDialog = () => {
      dialogVisible.value = true;
    };
    const cnValues = computed(() => {
      // Split the string into parts
      const parts = user?.value?.groups.split("^");
      // Extract CN values
      return parts
        .map((part) => {
          // Find CN value
          const cnMatch = part.match(/CN=([^,]+)/);
          return cnMatch ? cnMatch[1] : null;
        })
        .filter((cn) => cn); // Remove null values
    });
    // Create a comma-separated string
    const commaSeparatedCNs = computed(() => {
      // Join CN values with commas
      return cnValues.value.join(", ");
    });
    const closeDialog = () => {
      dialogVisible.value = false;
    };

    const showlogoutPage = () => {
      router.push({ path: "/logout" });
    };

    // Use onMounted for lifecycle hooks
    onMounted(() => {
      axios
        .get(CONFIG_ENDPOINT.GCMS_MAIN_PROJECT + "gcms")
        .then((response) => (constants.value = response.data))
        .catch((err) => {
          console.error(err);
        });
    });

    // Return the reactive variables
    return {
      otherRoute,
      constants,
      user,
      isAuthenticated,
      menuOpen,
      menuItems,
      commaSeparatedCNs,
      menuName,
      showConfirmationDialog,
      dialogVisible,
      showlogoutPage,
      closeDialog,
      userName,
      headerMessage,
    };
  },
};
</script>

<style>
@import "@/modules/gcmsmain/gcmsmainpage/assets/header.scss";
</style>
