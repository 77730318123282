import { validPUCDocketNumber } from "@/composables/validationRules";import { CONFIG_ENDPOINT } from "@/constants/Endpoints";
import CostAndFunding from "@/modules/cost-and-funding/components/CostAndFunding.vue";
import DesignAndConstruction from "@/modules/designandconstruction/components/DesignAndConstruction.vue";
import GradeCrossingAndBridges from "@/modules/grade-crossing-and-bridges/components/GradeCrossingAndBridges.vue";
import MpmsLocationInfo from "@/modules/locationInformation/components/MpmsLocationInfo.vue";
import MilestonesTab from "@/modules/projectinformation/components/MilestonesTab.vue";
import MpmsEnvironmentInfo from "@/modules/projectinformation/components/MpmsEnvironmentInfo.vue";
import PA1CallDetails from "@/modules/projectinformation/components/PA1CallDetails.vue";
import BannerMessage from "@/modules/shared/components/BannerMessage.vue";
import OnlineHelpPOC from "@/modules/shared/components/OnlineHelpPOC.vue";
import PrimaryButton from "@/modules/shared/components/PrimaryButton.vue";
import SecondaryButton from "@/modules/shared/components/SecondaryButton.vue";
import axios from "axios";
import { computed, onMounted, ref, watch } from "vue";
import { onBeforeRouteLeave, useRoute } from "vue-router";

export default {
  name: "TabsView",
  components: {
    MpmsEnvironmentInfo,
    MpmsLocationInfo,
    PA1CallDetails,
    DesignAndConstruction,
    CostAndFunding,
    GradeCrossingAndBridges,
    PrimaryButton,
    SecondaryButton,
    BannerMessage,
    MilestonesTab,
    OnlineHelpPOC,
  },
  emits: ["refreshMpmsData", "BannerUpdate"],
  setup(props, { emit }) {
    let panel = ref([0, 1]);
    const route = useRoute();
    let projectDetails = ref(null);
    const tab = ref("projectInformation");
    const currentTab = ref("projectInformation");
    let projectId = ref(null);
    let showTabs = ref(true);
    let hidePA1Call = ref(false);
    let previousMPMSValue = ref(null);
    let updatedMPMSValue = ref({});
    let completeScopingCheck = ref(null);
    let saveClicked = ref(null);
    let cancelClicked = ref(null);
    let showBannerMessage = ref(false);
    let showErrorBannerMessage = ref(false);
    let errorList = ref([]);
    let errorHeading = ref("");
    const isInitialised = ref(false);
    const valueChanged = ref(false);
    let ScopingComment = ref(null);
    let projectComment = ref(null);
    onMounted(async () => {
      isInitialised.value = false;
      valueChanged.value = false;
      getData();
    });

    watch(
      route,
      (to) => {
        projectId.value = to.params.id;
        if (route.path === "/GCMS/" + projectId.value && to.name === "Tabs") {
          getData();
        }
      },
      { flush: "pre", immediate: true, deep: true }
    );
    const getCurrentTab = computed({
      get: () => {
        return currentTab?.value;
      },
    });
    const onTabChange = (eventTab) => {
      if (isInitialised.value && valueChanged.value) {
        const answer = window.confirm(
          "You have unsaved data. Are you sure to leave the page?"
        );
        if (answer) {
          emitErrorBanner(false);
          emitBanner(false);
          valueChanged.value = false;
          currentTab.value = eventTab;
        } else {
          currentTab.value = tab.value;
        }
      } else {
        tab.value = eventTab;
        currentTab.value = eventTab;
        emitErrorBanner(false);
        emitBanner(false);
        valueChanged.value = false;
        isInitialised.value = false;
      }
    };
    const saveDisabled = ref(true);
    const milestoneTabValueChangeStatus = ref(false);
    const MilestonesTabValueChanged = (event) => {
      if (event) {
        isInitialised.value = true;
      }
      milestoneTabValueChangeStatus.value = event;
    };
    watch(
      () => [
        updatedMPMSValue.value,
        previousMPMSValue.value,
        ScopingComment.value,
        projectComment.value,
        milestoneTabValueChangeStatus.value,
      ],
      () => {
        valueChanged.value = isInitialisedValid();
        saveDisabled.value = checkPageSaveDisabled.value;
      },
      { deep: true }
    );
    function getData() {
      previousMPMSValue.value = {};
      updatedMPMSValue.value = {};
      checkScoping();
      axios
        .get(CONFIG_ENDPOINT.PROJECT_INFO_INFO + projectId.value)
        .then((response) => {
          projectDetails.value = response.data;
          if (projectDetails.value.gcmsProject?.safetyProject == "N") {
            hidePA1Call.value = true;
          } else {
            hidePA1Call.value = false;
          }
        })
        .catch((err) => {
          projectDetails.value = null;
        });
    }
    function checkScoping() {
      axios
        .get(CONFIG_ENDPOINT.MPMS_SCOPING_CHECK + projectId.value)
        .then((response) => {
          completeScopingCheck.value = response.data;
        })
        .catch((err) => {
          completeScopingCheck.value = null;
        });
    }
    function gradeCrossingClicked() {
      panel.value = [0, 1];
    }
    const previousValue = (value) => {
      previousMPMSValue.value = value;
    };
    const updatedValues = (value) => {
      let initialValue = updatedMPMSValue.value;
      if (
        Object.keys(initialValue).includes("pucDocketNumberDate") ||
        Object.keys(initialValue).includes("pucDocketNumber") ||
        Object.keys(initialValue).includes("crossingScopeDesc") ||
        Object.keys(initialValue).includes("section130projecttypeCode")
      ) {
        isInitialised.value = true;
      }
      if (Object.keys(value).includes("pucDocketNumberDate")) {
        updatedMPMSValue.value.pucDocketNumberDate = value.pucDocketNumberDate;
      }
      if (Object.keys(value).includes("pucDocketNumber")) {
        updatedMPMSValue.value.pucDocketNumber = value.pucDocketNumber;
      }
      if (Object.keys(value).includes("crossingScopeDesc")) {
        updatedMPMSValue.value.crossingScopeDesc = value.crossingScopeDesc;
      }
      if (Object.keys(value).includes("section130projecttypeCode")) {
        updatedMPMSValue.value.section130projecttypeCode =
          value.section130projecttypeCode;
      }

      saveClicked.value = false;
      cancelClicked.value = false;
    };
    const commentUpdate = (event) => {
      isInitialised.value = true;
      if (event.commentType === "Scoping Field View Comments") {
        ScopingComment.value = event.comment;
      } else {
        projectComment.value = event.comment;
      }

      saveClicked.value = false;
      cancelClicked.value = false;
    };
    const objectsAreSame = (x, y) => {
      let objectsAreSame = true;
      for (let propertyName in y) {
        if (x[propertyName] !== y[propertyName]) {
          objectsAreSame = false;
          break;
        }
      }
      return objectsAreSame;
    };
    const isInitialisedValid = () => {
      return (
        (isInitialised.value &&
          (updatedMPMSValue.value?.crossingScopeDesc ||
            updatedMPMSValue.value?.section130projecttypeCode ||
            updatedMPMSValue.value?.pucDocketNumber ||
            updatedMPMSValue.value?.pucDocketNumberDate) &&
          !objectsAreSame(updatedMPMSValue.value, previousMPMSValue.value)) ||
        ScopingComment.value ||
        projectComment.value ||
        milestoneTabValueChangeStatus.value
      );
    };
    const isEmptyPucDocketNumberAndDate = () => {
      return (
        !updatedMPMSValue.value?.pucDocketNumber &&
        !updatedMPMSValue.value?.pucDocketNumberDate
      );
    };
    const isPucDocketNumberInvalid = () => {
      return (
        updatedMPMSValue.value?.pucDocketNumber &&
        validPUCDocketNumber(updatedMPMSValue.value?.pucDocketNumber) ===
          "PUC Docket # must be between 8-14 characters and in the format A-99999999 or A-9999-9999999."
      );
    };
    const isPucDocketNumberMissingDate = () => {
      return (
        updatedMPMSValue.value?.pucDocketNumber &&
        !updatedMPMSValue.value?.pucDocketNumberDate
      );
    };
    const checkPageSaveDisabled = computed({
      get: () => {
        let isPageSaveDisabled = false;
        const noChangesDetected = !isInitialised.value && !valueChanged.value;
        if (
          isEmptyPucDocketNumberAndDate() ||
          updatedMPMSValue.value?.crossingScopeDesc ||
          updatedMPMSValue.value?.section130projecttypeCode
        ) {
          isPageSaveDisabled = false;
        }
        if (
          !updatedMPMSValue.value?.pucDocketNumber &&
          !updatedMPMSValue.value?.pucDocketNumberDate &&
          !ScopingComment.value &&
          !projectComment.value
        ) {
          isPageSaveDisabled = true;
        }
        if (isPucDocketNumberInvalid()) {
          isPageSaveDisabled = true;
        }
        if (isPucDocketNumberMissingDate()) {
          isPageSaveDisabled = true;
        }
        if (noChangesDetected) {
          return true;
        }
        return isPageSaveDisabled;
      },
    });
    window.onbeforeunload = function () {
      if (isInitialised.value && valueChanged.value) {
        return "You have unsaved data. Are you sure to leave the page?";
      }
    };

    onBeforeRouteLeave((to, from, next) => {
      if (isInitialised.value && valueChanged.value) {
        const answer = window.confirm(
          "You have unsaved data. Are you sure to leave the page?"
        );
        if (answer) {
          emitErrorBanner(false);
          emitBanner(false);
          valueChanged.value = false;
          isInitialised.value = false;
          next();
        } else {
          next(false);
        }
      } else {
        emitErrorBanner(false);
        emitBanner(false);
        valueChanged.value = false;
        isInitialised.value = false;
        next();
      }
    });
    const saveMpmsDetails = () => {
      const payload = {
        pucDocketDate: new Date(
          new Date(updatedMPMSValue.value.pucDocketNumberDate).setSeconds(0, 1)
        ),
        pucDocketNumber: updatedMPMSValue.value.pucDocketNumber,
        crossingScopeDesc: updatedMPMSValue.value.crossingScopeDesc,
        section130projecttypeCode:
          updatedMPMSValue.value.section130projecttypeCode,
      };
      axios
        .post(CONFIG_ENDPOINT.UPDATE_MPMS_INFO + projectId.value, payload)
        .then((res) => {
          getData();
          emit("refreshMpmsData");
          window.scrollTo(0, 0);
          emitBanner(true);
          saveClicked.value = true;
          isInitialised.value = false;
          valueChanged.value = false;
        })
        .catch((err) => {});
    };
    const cancelMpmsClicked = () => {
      getData();
      cancelClicked.value = true;
      emit("refreshMpmsData");
    };

    const completeScopingClicked = () => {
      if (
        completeScopingCheck.value?.actionsHeading ||
        completeScopingCheck.value?.actionList?.length
      ) {
        window.scrollTo(0, 0);
        errorList.value = completeScopingCheck.value?.actionList;
        errorHeading.value = completeScopingCheck.value?.actionsHeading;
        emitErrorBanner(true);
      } else {
        axios
          .post(CONFIG_ENDPOINT.MPMS_SCOPING_CHECK + projectId.value)
          .then((response) => {
            completeScopingCheck.value = response.data;
            getData();
            emit("refreshMpmsData");
            window.scrollTo(0, 0);
            emitErrorBanner(false);
            emitBanner(true);
          })
          .catch((err) => {
            completeScopingCheck.value = null;
            emitErrorBanner(true);
          });
      }
    };

    const emitBanner = (bannerValue) => {
      showBannerMessage.value = bannerValue;
      emit("BannerUpdate", {
        bannerType: "success",
        bannerMessage: "Saved Successfully.",
        showBanner: showBannerMessage.value,
      });
    };

    const emitErrorBanner = (bannerValue) => {
      showErrorBannerMessage.value = bannerValue;
      emit("BannerUpdate", {
        bannerType: "error",
        bannerMessage: errorHeading.value,
        showBanner: showErrorBannerMessage.value,
        hasList: true,
        errorList: errorList.value,
      });
    };

    return {
      saveMpmsDetails,
      cancelMpmsClicked,
      checkPageSaveDisabled,
      projectDetails,
      tab,
      projectId,
      showTabs,
      getData,
      panel,
      gradeCrossingClicked,
      hidePA1Call,
      previousValue,
      updatedValues,
      completeScopingClicked,
      completeScopingCheck,
      saveClicked,
      cancelClicked,
      commentUpdate,
      showBannerMessage,
      showErrorBannerMessage,
      errorList,
      errorHeading,
      emitBanner,
      saveDisabled,
      MilestonesTabValueChanged,
      getCurrentTab,
      onTabChange,
      currentTab,
    };
  },
};
