<template>
  <div v-if="showBanner">
    <BannerMessage
      v-if="isSuccess"
      :successBanner="true"
      :message=bannerMessage
      @hideBanner="hideBannerMessage()"
    ></BannerMessage>
    <BannerMessage
      v-if="!isSuccess"
      :errorBanner="true"
      :message=bannerMessage
      @hideBanner="hideBannerMessage()"
    ></BannerMessage>
  </div>
  <div class="header-details allPagecolor">
    <div class="detail">
      <h3 class="dotHeader">
        <b>DOT# {{ dotId }}</b>
      </h3>
      <div class="mprs-header-div mt-20">
        <div class="env-infp">
          <div class="env-content">
            <v-row justify="end" style="margin-top: -50px; margin-bottom: 10px">
              <PrimaryButton
                :buttonText="'Show Grade Crossing On One Map'"
                @buttonClicked="navigateToGISMap('crossing', dotId)"
              >
              </PrimaryButton>
            </v-row>
            <v-row class="center">
              <v-col
                cols="4"
                v-for="column in crossingDetails"
                :key="column.key"
              >
                <label class="mpms-env-label"
                  ><v-row
                    ><v-col cols="4"
                      ><b class="d-flex justify-content-end text-right"
                        >{{ column.label }}
                      </b></v-col
                    ><v-col cols="8">{{
                      crossingDetailsData?.[column.key]
                    }}</v-col>
                  </v-row>
                </label>
              </v-col>
            </v-row>
            <br />
            <br />

            <v-row class="center" style="margin-top: -10px">
              <v-col cols="6">
                <label class="mpms-env-label"
                  ><v-row
                    ><v-col cols="4"
                      ><b class="d-flex justify-content-end text-right"
                        >PennDOT Identified Owner:
                      </b></v-col
                    ><v-col cols="8">
                      <v-select
                        :items="railroadNames"
                        v-model="owner"
                        :disabled="!isUserDOTAccess.update"
                      ></v-select>
                    </v-col>
                  </v-row>
                </label>
              </v-col>

              <v-col cols="6">
                <label class="mpms-env-label"
                  ><v-row
                    ><v-col cols="4"
                      ><b class="d-flex justify-content-end text-right"
                        >FRA Owner:
                      </b></v-col
                    ><v-col cols="8">{{
                      ownerOperatorDetails?.fraOwner
                    }}</v-col>
                  </v-row>
                </label>
              </v-col>
            </v-row>

            <v-row class="center" style="margin-top: -10px">
              <v-col cols="6">
                <label class="mpms-env-label"
                  ><v-row
                    ><v-col cols="4"
                      ><b class="d-flex justify-content-end text-right"
                        >Date Updated:
                      </b></v-col
                    ><v-col cols="8">{{
                      ownerOperatorDetails?.pndtOwnerDate
                    }}</v-col>
                  </v-row>
                </label>
              </v-col>

              <v-col cols="6">
                <label class="mpms-env-label"
                  ><v-row
                    ><v-col cols="4"
                      ><b class="d-flex justify-content-end text-right"
                        >Date Updated:
                      </b></v-col
                    ><v-col cols="8">{{
                      ownerOperatorDetails?.fraOwnerDate
                    }}</v-col>
                  </v-row>
                </label>
              </v-col>
            </v-row>
            <br />

            <v-row class="center" style="margin-top: -10px">
              <v-col cols="6">
                <label class="mpms-env-label"
                  ><v-row
                    ><v-col cols="4"
                      ><b class="d-flex justify-content-end text-right"
                        >PennDOT Identified Operator:
                      </b></v-col
                    ><v-col cols="8">
                      <v-select
                        :items="railroadNames"
                        v-model="operator"
                        :disabled="!isUserDOTAccess.update"
                      ></v-select>
                    </v-col>
                  </v-row>
                </label>
              </v-col>

              <v-col cols="6">
                <label class="mpms-env-label"
                  ><v-row
                    ><v-col cols="4"
                      ><b class="d-flex justify-content-end text-right"
                        >FRA Operator:
                      </b></v-col
                    ><v-col cols="8">{{
                      ownerOperatorDetails?.fraOperator
                    }}</v-col>
                  </v-row>
                </label>
              </v-col>
            </v-row>

            <v-row class="center" style="margin-top: -10px">
              <v-col cols="6">
                <label class="mpms-env-label"
                  ><v-row
                    ><v-col cols="4"
                      ><b class="d-flex justify-content-end text-right"
                        >Date Updated:
                      </b></v-col
                    ><v-col cols="8">{{
                      ownerOperatorDetails?.pndtOperatorDate
                    }}</v-col>
                  </v-row>
                </label>
              </v-col>

              <v-col cols="6">
                <label class="mpms-env-label"
                  ><v-row
                    ><v-col cols="4"
                      ><b class="d-flex justify-content-end text-right"
                        >Date Updated:
                      </b></v-col
                    ><v-col cols="8">{{
                      ownerOperatorDetails?.fraOperatorDate
                    }}</v-col>
                  </v-row>
                </label>
              </v-col>
            </v-row>
            <div
              class="w-100 d-flex justify-content-end"
              style="margin-bottom: -30px"
            >
              <SecondaryButton
                :buttonText="'Cancel'"
                @buttonClicked="cancelSave"
              ></SecondaryButton>
              <PrimaryButton
                @buttonClicked="postOwnerOperatorDetails"
                :buttonText="'Save'"
                class="ml-10"
                :disabled="!saveEnabled || !isUserDOTAccess.update"
              ></PrimaryButton>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div>
    <v-tabs v-model="tab" dark align-tabs="start">
      <v-tab value="crossingDetails" @click="hideBannerMessage()">
        Crossing Details
      </v-tab>
      <v-tab value="documents" @click="hideBannerMessage()">
        Crossing Documents
      </v-tab>
      <v-tab
        value="PucDocuments"
        @click="
          {
            hideBannerMessage();
            resetPUC = true;
          }
        "
        >PUC Documents</v-tab
      >

      <v-tab value="projects" @click="hideBannerMessage()">Projects</v-tab>
      <v-tab value="Inspections" @click="hideBannerMessage()"
        >Inspections
      </v-tab>
      <v-tab value="CrossingHistory" @click="hideBannerMessage()"
        >Crossing History
      </v-tab>
    </v-tabs>
    <v-window v-model="tab">
      <v-window-item value="crossingDetails">
        <v-card flat>
          <div>
            <CrossingDetailsTabView
              :tab="tab"
              :dotId="dotId"
              @showBanner="showBannerMessage"
            ></CrossingDetailsTabView>
          </div>
        </v-card>
      </v-window-item>
      <v-window-item value="CrossingPhotos">
        <v-card flat>
          <div>
            <p></p>

            <p></p>

            <p></p>

            <p></p>

            <p class="mb-0"></p>
          </div>
        </v-card>
      </v-window-item>
      <v-window-item value="documents">
        <v-card flat>
          <div>
            <GradeCrossingDocuments
              :tab="tab"
              :dotId="dotId"
              @showBanner="showBannerMessage"
              :resetPUC="resetPUC"
            ></GradeCrossingDocuments>
          </div>
        </v-card>
      </v-window-item>

      <v-window-item value="PucDocuments">
        <v-card flat>
          <div>
            <div>
              <PucDocuments
                :tab="tab"
                :dotId="dotId"
                @showBanner="showBannerMessage"
                :resetPUC="resetPUC"
              ></PucDocuments>
            </div>
          </div>
        </v-card>
      </v-window-item>

      <v-window-item value="projects">
        <GradeCrossingProjects
          :tab="tab"
          :id="this.dotId"
        ></GradeCrossingProjects>
      </v-window-item>
      <v-window-item value="Inspections">
        <v-card flat>
          <div>
            <Inspections
              :tab="tab"
              :dotId="dotId"
              @showBanner="showBannerMessage"
            ></Inspections>
          </div>
        </v-card>
      </v-window-item>
      <v-window-item value="CrossingHistory">
        <v-card flat>
          <div>
            <GradeCrossingHistory
              :tab="tab"
              :dotId="dotId"
            ></GradeCrossingHistory>
          </div>
        </v-card>
      </v-window-item>
    </v-window>
  </div>
</template>
<script>
import GradeInventoryDetails from "@/modules/dot-details/scripts/DotDetails.js";
export default GradeInventoryDetails;
</script>
<style>
@import "@/assets/css/style.scss";
@import "@/assets/css/main.scss";
</style>
